export const notFound = {
  redirect: {
    permanent: false,
    destination: '/not_found',
  },
}

export const serviceUnavailable = {
  redirect: {
    permanent: false,
    destination: '/service_unavailable',
  },
}
