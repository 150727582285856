/* eslint-disable security/detect-object-injection */

import { IPage } from '@bees-web/nfa-types'
import { BareFetcher } from 'swr'
import { simplify } from '../core/tools/data'

type TemplateData = IPage
type TemplateCache = { [key: string]: { data: TemplateData; expires: number } }

export class TemplateProvider {
  private fetcher: BareFetcher<IPage>
  static cache: TemplateCache = {}
  private readonly TTL = 10 * 60 * 1000

  constructor(fetcher: BareFetcher<TemplateData>) {
    if (fetcher) {
      this.fetcher = fetcher
    } else {
      throw new Error('Invalid fetcher')
    }
  }

  private getCacheKey(
    templateType: 'pages' | 'blocks',
    templateName: string,
    templateParams: Record<string, unknown>
  ): string {
    const query = new URLSearchParams(
      Object.entries(templateParams).map(([key, value]) => [
        key,
        value.toString(),
      ])
    )
    return `api/${templateType}/${templateName}?${query}`
  }

  async getTemplate(
    templateName: string,
    templateParams: Record<string, unknown>,
    useCache: boolean = true
  ): Promise<TemplateData> {
    const key = this.getCacheKey('pages', templateName, templateParams)

    if (useCache) {
      const cached = TemplateProvider.cache[key]

      if (cached) {
        if (cached.expires > Date.now()) {
          return cached.data
        }
        this.refreshTemplate(key)
        return cached.data
      }
      await this.refreshTemplate(key)
      return TemplateProvider.cache[key]?.data || null
    }

    return await this.fetchTemplate(key)
  }

  private async fetchTemplate(key: string): Promise<TemplateData> {
    const response = await this.fetcher(key)
    return simplify(response)
  }

  private async refreshTemplate(key: string): Promise<void> {
    try {
      const data = await this.fetchTemplate(key)
      TemplateProvider.cache[key] = {
        data,
        expires: Date.now() + this.TTL,
      }
    } catch (error) {
      console.error('Failed to refresh template:', error.message)
    }
  }
}
